import styled from 'styled-components';

const AnimationWrapStyle = styled.div`
	.filterCont {
		position: relative;
		z-index: 2;

		.filterScrollWrap {
			overflow-x: auto;
			overflow-y: hidden;
			scroll-snap-type: both mandatory;
			padding-left: var(--containerPaddingLR);
			padding-right: var(--containerPaddingLR);
			margin-bottom: var(--titleToContent);

			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			/* &::-webkit-scrollbar {
				height: 4px;
				mix-blend-mode: difference;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 100px;
				mix-blend-mode: difference;
				background: linear-gradient(to right, var(--color1) var(--containerPaddingLR), var(--color2) var(--containerPaddingLR), var(--color2) calc(100% - var(--containerPaddingLR)), var(--color1) 10%);
			} */

			.filterWrap {
				display: flex;
				width: max-content;

				.filtersItem {
					cursor: pointer;
					scroll-snap-align: center;
					border-radius: 37px;
					border: 2px solid var(--color2);
					margin-right: var(--sp2x);
					opacity: 0;
					position: relative;
					padding: var(--sp1x) var(--sp4x);

					&.active {
						background-color: var(--color2);
						color: var(--color1);

						.textVisible {
							font-style: var(--ffItalic);
						}
					}

					&:last-child {
						margin-right: 0;
					}

					.textHide {
						opacity: 0;
						visibility: hidden;
					}

					.textVisible {
						white-space: pre;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: matrix(1, 0, 0, 1, 0, 0) translate3d(-50%, -50%, 0);
					}

					@media (pointer: fine) {
						@media (hover: hover) {
							&:hover:not(.active) {
								.textVisible {
									font-style: var(--ffItalic);
								}

								p {
									animation-name: animation;
									animation-duration: 3s;
									animation-delay: 0s;
									animation-iteration-count: infinite;
									animation-direction: alternate;
									animation-fill-mode: none;
								}
							}
						}
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.filterScrollWrap {
				display: flex;
				justify-content: flex-end;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.filterScrollWrap {
				.filterWrap {
					.filtersItem {
						margin-right: var(--sp2-5x);
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			.filterScrollWrap {
				width: 100%;
				margin-left: auto;
				margin-right: auto;

				.filterWrap {
					.filtersItem {
						margin-right: var(--sp4x);

						.textHide {
							margin: 12px 45px;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) {
		/* .filterScrollWrap {
			.filterWrap {
				padding-bottom: var(--sp2x);
			}
		} */
	}

	@keyframes animation {
		0% {
			transform: matrix(1, 0, 0, 1, 0, 0);
			animation-timing-function: linear;
		}

		7.142857142857142% {
			transform: matrix(1, 0, 0, 1, 0, 2);
			animation-timing-function: linear;
		}

		14.285714285714285% {
			transform: matrix(1, 0, 0, 1, -4, 5);
			animation-timing-function: linear;
		}

		21.428571428571427% {
			transform: matrix(1, 0, 0, 1, 3, 1);
			animation-timing-function: linear;
		}

		28.57142857142857% {
			transform: matrix(1, 0, 0, 1, 0, -8);
			animation-timing-function: linear;
		}

		35.71428571428571% {
			transform: matrix(1, 0, 0, 1, -4, -1);
			animation-timing-function: linear;
		}

		42.85714285714285% {
			transform: matrix(1, 0, 0, 1, 0, 2);
			animation-timing-function: linear;
		}

		49.999999999999986% {
			transform: matrix(1, 0, 0, 1, -4, 3);
			animation-timing-function: linear;
		}

		57.14285714285713% {
			transform: matrix(1, 0, 0, 1, -1, 2);
			animation-timing-function: linear;
		}

		64.28571428571426% {
			transform: matrix(1, 0, 0, 1, -2, -5);
			animation-timing-function: linear;
		}

		71.4285714285714% {
			transform: matrix(1, 0, 0, 1, 1, 0);
			animation-timing-function: linear;
		}

		78.57142857142854% {
			transform: matrix(1, 0, 0, 1, 4, 6);
			animation-timing-function: linear;
		}

		85.7142857142857% {
			transform: matrix(1, 0, 0, 1, -5, 3);
			animation-timing-function: linear;
		}

		92.85714285714283% {
			transform: matrix(1, 0, 0, 1, -6, -3);
			animation-timing-function: linear;
		}

		100% {
			transform: matrix(1, 0, 0, 1, 0, -2);
		}
	}
`;

export default AnimationWrapStyle;
