import { memo, useEffect } from 'react';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@components/global/Footer'), { ssr: false });

const Page = memo(({ mainProps, ...props }) => {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';

		const handleBeforeUnload = () => {
			window.history.scrollRestoration = 'manual';
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.history.scrollRestoration = 'auto';
		};
	}, []);

	return (
		<>
			<main
				{...mainProps}
				className={`page ${props.className || ''}`.trim()}>
				{props.children}
			</main>
			<Footer />
		</>
	);
});

Page.displayName = 'Page';

export default Page;
