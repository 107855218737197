import styled from 'styled-components';

const HomeStyle = styled.section`
	&.homePage {
		.h1 {
			.item-1 {
				font-weight: var(--ffMedium);
			}

			.item-2 {
				font-weight: var(--ffLight);
			}

			.item-3 {
				margin-left: var(--sp4x);
				display: block;
			}

			.item-4,
			.item-5,
			.item-6 {
				display: block;
			}

			span {
				color: white;
			}
		}

		.section-item {
			&.min-height {
				min-height: calc(100vh - var(--headerHeight));
			}
		}

		.not-margin {
			margin-bottom: 0 !important;

			.top_section_info_wrap,
			.home_animation_element_img {
				padding-bottom: var(--sectionToSection);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			.not-margin {
				.top_section_info_wrap {
					padding-bottom: calc(var(--sectionToSection) / 2);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.h1 {
				.item-3 {
					margin-left: 100px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			.container .container {
				padding: 0;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.section-item {
				&:first-child {
					position: relative;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.h1 {
				.item-3 {
					margin-left: 124px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.h1 {
				.item-3 {
					margin-left: 148px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.h1 {
				.item-3 {
					margin-left: 172px;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			.h1 {
				.item-3 {
					margin-left: 200px;
				}
			}
		}
	}
`;

export default HomeStyle;
