import { memo as Memo, useMemo, useEffect, useRef, useCallback, useState } from 'react';
import { gsap, TimelineMax, Power3 } from 'gsap';
import { TextPlugin } from 'gsap/dist/TextPlugin';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Helpers
import breakString from '@helpers/breakString';

//* Components
import Text from '@/components/global/Text';
import SocIcon from '@/components/global/SocIcon';
import CustomLink from '@components/global/CustomLink';
import ZiggyHomeTop from '@components/global/SvgComponents/ZiggyHomeTop';

//* Styles
import HomeTopStyle from './style';

gsap.registerPlugin(TextPlugin);

const HomeTop = Memo(({ data, translate, selectedLang, winWidth }) => {
	const topRef1 = useRef();
	const topRef2 = useRef();
	const svgRef = useRef();
	const textRef = useRef();

	let active = 1;

	const [play, setPlay] = useState(true);
	const tl = useMemo(() => new TimelineMax({ paused: true }), []);

	const textAnim = useMemo(() => {
		return new TimelineMax({
			repeat: -1,
			paused: true,
			onRepeat: () => {
				const active = getRandomString();
				textAnim.set(
					textRef.current,
					{
						text: () => translate(`startJourneyMiddleTextLine3_${active}`),
					},
					0
				);
			},
		});
	}, [translate]);

	const getRandomString = useCallback(() => {
		let newNum;
		do {
			newNum = Math.floor(Math.random() * 3) + 1;
		} while (newNum === active);
		return newNum;
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	const onScroll = useCallback(() => {
		const el = topRef2?.current?.getBoundingClientRect();
		if (el) setPlay(el.y > -el.height && el.y < window.innerHeight);
	}, []);

	useEffect(() => {
		if (play) {
			textAnim.play();
			tl.play();
		} else {
			textAnim.pause();
			tl.pause();
		}
	}, [play, textAnim, tl]);

	useEffect(() => {
		tl.clear().pause();
		tl.staggerTo(
			topRef1.current?.childNodes,
			0,
			{
				color: 'var(--color2)',
			},
			0.1
		).to(
			topRef1.current?.childNodes[1],
			0,
			{
				css: { fontStyle: 'italic', fontWeight: 300 },
			},
			0.2
		);

		const el = winWidth <= 767 ? [topRef2.current?.childNodes] : [topRef2.current?.childNodes[0]?.childNodes, topRef2.current?.childNodes[1]];

		tl.staggerTo(
			el,
			0,
			{
				delay: 0.1,
				opacity: 1,
				color: 'var(--color2)',
			},
			0.1
		);

		tl.fromTo(
			svgRef.current,
			1.5,
			{
				transformOrigin: '50%',
				rotation: -25,
			},
			{
				transformOrigin: '50%',
				rotation: 20,
				repeat: -1,
				yoyo: true,
				ease: Power3.easeInOut,
			},
			0
		);

		tl.play();

		const active = getRandomString();
		textAnim.set(
			textRef.current,
			{
				text: () => translate(`startJourneyMiddleTextLine3_${active}`),
			},
			0.4
		);

		return () => {
			tl.clear().kill();
			textAnim.clear().kill();
		};
	}, [winWidth, textAnim]);

	const titleCont = useMemo(
		() => (
			<div className={'title'}>
				<Text
					tag={'h1'}
					ref={topRef1}
					className={'h1 font-poppins font-semi-bold'}>
					{breakString(data.title)}
				</Text>
			</div>
		),
		[data]
	);

	const subtitleCont = <Text className={`p-m font-anonymous font-regular subtitle`}>{data.subtitle}</Text>;

	const descriptionCont = <Text className={`size-new font-poppins font-light description`}>{data.description}</Text>;

	const socIconCont = (
		<div className={'social_icon_container'}>
			<SocIcon
				type={2}
				menuIcon
				isSocial
			/>
		</div>
	);

	const animationCont = (
		<div className={'home_animation_element_img'}>
			<ZiggyHomeTop />
		</div>
	);

	const animationTextCont = useMemo(
		() => (
			<CustomLink
				url={'/contact-form/'}
				className={'home_animation_element_text_wrap'}
				ariaLabel={'ready to go beyond the standard product experience? Start your journey here!'}>
				<div className={`home_animation_element_top ${selectedLang === 'de' ? 'home_animation_element_top_ls' : ''}`}>
					<Text className={'font-anonymous font-regular'}>
						<Text
							tag={'span'}
							text={'startJourneyMiddleTextLine1'}
						/>
						<Text
							tag={'span'}
							text={'startJourneyMiddleTextLine2'}
						/>
						<Text tag={'span'}>
							<strong
								ref={textRef}
								className={`font-italic font-bold`}>
								{translate('startJourneyMiddleTextLine3_1')}
							</strong>
							{translate('startJourneyMiddleTextLine3')}
						</Text>
					</Text>
				</div>

				<div className={`home_animation_element_bottom ${selectedLang === 'de' ? 'home_animation_element_bottom_ls' : ''}`}>
					<svg
						ref={svgRef}
						className={`circle-svg circle_svg`}
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 310 310'>
						<defs>
							<path
								id={`circlePath`}
								className={`circle`}
								d='M2.1,196.1c19.5,48.1,80.4,97.3,153.8,97.3c74.1,0,135.1-46.1,154.2-98.3'
							/>
						</defs>
						<text>
							<textPath
								id='masterTextPath'
								xlinkHref='#circlePath'
								className={`font-anonymous font-bold circle_text`}>
								{translate('startYourJurney')}
							</textPath>
						</text>
					</svg>
				</div>
			</CustomLink>
		),
		[selectedLang]
	);

	const infoDesktopCont = (
		<>
			<div className={'top_section_info_wrap'}>
				{subtitleCont}
				{descriptionCont}
				{socIconCont}
			</div>
			<div className={'home_animation_element_wrap'}>
				{animationCont}
				{animationTextCont}
			</div>
		</>
	);

	const infoMobileCont = (
		<>
			{subtitleCont}
			<div className={'home_animation_element_wrap'}>{animationCont}</div>
			{socIconCont}
			{descriptionCont}
			<div className={'home_animation_element_wrap'}>{animationTextCont}</div>
		</>
	);

	return (
		<HomeTopStyle>
			{titleCont}
			<div
				ref={topRef2}
				className={'top_section_wrap'}>
				{winWidth <= 767 ? infoMobileCont : infoDesktopCont}
			</div>
		</HomeTopStyle>
	);
});

export default withUIContext(withLanguageContext(HomeTop, ['translate', 'selectedLang']), ['winWidth']);
